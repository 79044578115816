import { createSelector } from '@reduxjs/toolkit';
import {
  filterByValueType,
  filterFieldsByOnlyChanged,
  filterFieldsBySearchQuery,
  filterFieldsByStructurePath,
  generateFileFields,
  generateFileStructure,
  generateId,
  highlightText,
  removeAllSpaces,
  removeEmptyObjects,
  removeHighlighting,
  sortFieldsByKeyAndPath,
  sortStructure,
} from 'components/Editor/helpers';
import { last, take, toLower, trim } from 'lodash';
import { RootState } from 'store/store';

import { KEY_DIVIDER } from './constants';
import { SavefileField, SavefileObject } from './types';

export const editorSelector = (state: RootState) => state.editor;
export const editorModeSelector = (state: RootState) =>
  editorSelector(state).mode;
export const savefileSelector = (state: RootState) =>
  editorSelector(state).file;
export const savefileNameSelector = (state: RootState) =>
  editorSelector(state).name;
export const savefileTokenSelector = (state: RootState) =>
  editorSelector(state).token;
export const isSavefileUploadedSelector = (state: RootState) =>
  Boolean(savefileSelector(state));
export const structureActiveSelector = (state: RootState) =>
  editorSelector(state).structure.active;
export const structureOpenItemsSelector = (state: RootState) =>
  editorSelector(state).structure.openItems;
export const structurePathSelector = (state: RootState) =>
  editorSelector(state).structure.path;

export const changedFieldsSelector = (state: RootState) =>
  editorSelector(state).changedFields;
export const changedFieldsVisibilitySelector = (state: RootState) =>
  editorSelector(state).changedFieldsVisibility;
export const searchFiltersSelector = (state: RootState) =>
  editorSelector(state).searchFilters;
export const searchMatchWholeWordSelector = (state: RootState) =>
  editorSelector(state).searchMatchWholeWord;
export const searchQuerySelector = (state: RootState) =>
  trim(editorSelector(state).searchQuery);
export const showOnlyChangedSelector = (state: RootState) =>
  editorSelector(state).showOnlyChanged;
const valueTypeFilterSelector = (state: RootState) =>
  editorSelector(state).valueTypeFilter;

export const contentTokenSelector = (state: RootState) =>
  editorSelector(state).contentToken;
export const documentTokenSelector = (state: RootState) =>
  editorSelector(state).documentToken;

export const searchQueryLowerNoSpaceSelector = createSelector(
  searchQuerySelector,
  (searchQuery) => toLower(removeAllSpaces(searchQuery)),
);

export const savefileObjectSelector = createSelector(
  savefileSelector,
  isSavefileUploadedSelector,
  (savefile, isSavefileUploaded): SavefileObject =>
    isSavefileUploaded ? removeEmptyObjects(JSON.parse(savefile)) : {},
);

export const preparedStructureSelector = createSelector(
  savefileObjectSelector,
  (savefileObject) => {
    return generateFileStructure(savefileObject);
  },
);

export const structureSelector = createSelector(
  preparedStructureSelector,
  (structure) => {
    return sortStructure(structure);
  },
);

export const preparedFieldsSelector = createSelector(
  savefileObjectSelector,
  (savefileObject) => {
    return generateFileFields(savefileObject);
  },
);

export const sortedFieldsSelector = createSelector(
  structurePathSelector,
  searchQuerySelector,
  preparedFieldsSelector,
  (structurePath, searchQuery, savefileFields) => {
    return sortFieldsByKeyAndPath(structurePath, searchQuery, savefileFields);
  },
);

export const filteredByValueTypeSelector = createSelector(
  sortedFieldsSelector,
  valueTypeFilterSelector,
  (sortedFields, valueTypeFilter) => {
    return filterByValueType(sortedFields, valueTypeFilter);
  },
);

export const filteredFieldsByOnlyChangedSelector = createSelector(
  changedFieldsSelector,
  showOnlyChangedSelector,
  filteredByValueTypeSelector,
  (changedFields, showOnlyChanged, filteredByValueTypeFields) => {
    return showOnlyChanged
      ? filterFieldsByOnlyChanged(changedFields, filteredByValueTypeFields)
      : filteredByValueTypeFields;
  },
);

export const filteredFieldsByStructurePathSelector = createSelector(
  structurePathSelector,
  filteredFieldsByOnlyChangedSelector,
  (structurePath, savefileFields) => {
    if (structurePath === '') {
      return savefileFields;
    }

    return filterFieldsByStructurePath(structurePath, savefileFields);
  },
);

export const filteredFieldsBySearchQuerySelector = createSelector(
  searchQuerySelector,
  filteredFieldsByOnlyChangedSelector,
  changedFieldsVisibilitySelector,
  searchFiltersSelector,
  searchMatchWholeWordSelector,
  (
    searchQuery,
    savefileFields,
    changedFieldsVisibility,
    searchFilters,
    searchMatchWholeWord,
  ) => {
    if (searchQuery === '') {
      return savefileFields;
    }

    return filterFieldsBySearchQuery(
      searchQuery,
      savefileFields,
      changedFieldsVisibility,
      searchFilters,
      searchMatchWholeWord,
    );
  },
);

export const savefileFieldsSelector = createSelector(
  filteredFieldsByOnlyChangedSelector,
  filteredFieldsBySearchQuerySelector,
  filteredFieldsByStructurePathSelector,
  searchQuerySelector,
  structurePathSelector,
  (
    filteredFieldsByOnlyChanged,
    filteredFieldsBySearchQuery,
    filteredFieldsByStructurePath,
    searchQuery,
    structurePath,
  ) => {
    if (searchQuery !== '') {
      return filteredFieldsBySearchQuery;
    }

    if (structurePath !== '') {
      return filteredFieldsByStructurePath;
    }

    return filteredFieldsByOnlyChanged;
  },
);

export const structureItemStateSelector = (id: string) =>
  createSelector(
    structureActiveSelector,
    structureOpenItemsSelector,
    (structureActive, structureOpenItems) => ({
      isActive: structureActive === id,
      isOpen: structureOpenItems[id],
    }),
  );

export const makePathAndKeySelector = ({
  path,
  keyValue,
}: Pick<SavefileField, 'keyValue' | 'path'>) =>
  createSelector(
    structurePathSelector,
    searchQueryLowerNoSpaceSelector,
    searchMatchWholeWordSelector,
    (structurePath, searchQueryNoSpaces, searchMatchWholeWord) => {
      let newKey: string = keyValue;
      let newPath: string[] = [];

      const isSearchQueryEmpty = searchQueryNoSpaces === '';

      const pathStart = `${
        structurePath ? `${structurePath}${KEY_DIVIDER}` : ''
      }`;
      const pathToShow = isSearchQueryEmpty
        ? path.replace(pathStart, '')
        : path;

      if (!isSearchQueryEmpty) {
        newKey = highlightText(
          searchQueryNoSpaces,
          keyValue,
          searchMatchWholeWord,
        );

        if (pathToShow) {
          newPath = highlightText(
            searchQueryNoSpaces,
            pathToShow,
            searchMatchWholeWord,
          ).split(KEY_DIVIDER);
        }
      } else if (structurePath !== path) {
        newPath = pathToShow.split(KEY_DIVIDER);
      }

      // TODO: move to helpers
      const getNewActiveItem = (index: number) => {
        const newActivePath = take(newPath, index);
        const newActivePathString = newActivePath.join(KEY_DIVIDER);
        const key = last(newActivePath) || '';
        const restPath = take(newActivePath, newActivePath.length - 1).join(
          KEY_DIVIDER,
        );
        const pathForId = isSearchQueryEmpty
          ? `${restPath ? pathStart : structurePath}${restPath}`
          : restPath;

        const activeId = generateId(key, pathForId);
        const activePath = isSearchQueryEmpty
          ? `${pathStart}${newActivePathString}`
          : newActivePathString;

        return {
          activeId: removeHighlighting(activeId),
          activePath: removeHighlighting(activePath),
        };
      };

      return {
        getNewActiveItem,
        newKey,
        newPath,
      };
    },
  );

export const makeTextValueSelector = (value: string) =>
  createSelector(
    searchQuerySelector,
    searchMatchWholeWordSelector,
    (searchQuery, searchMatchWholeWord) =>
      value && searchQuery
        ? highlightText(searchQuery, value, searchMatchWholeWord)
        : '',
  );

export const makeChangedFieldSelector = (id: string) =>
  createSelector(changedFieldsSelector, (changedFields) => {
    return changedFields[id];
  });

export const downloadDataSelector = createSelector(
  savefileNameSelector,
  savefileTokenSelector,
  changedFieldsSelector,
  (name, token, fields) => ({
    fields,
    name,
    token,
  }),
);
