import { createReducer } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import * as actions from './actions';
import { AlertType } from './types';

export type AlertsState = {
  system: AlertType;
};

export const alertsInitialState: AlertsState = {
  system: {
    id: '',
    text: '',
  },
};

export const alertsReducer = createReducer<AlertsState>(
  alertsInitialState,
  (builder) =>
    builder
      .addCase(actions.showSystemAlertAction, (state, { payload }) => {
        state.system = {
          id: nanoid(),
          ...payload,
        };
      })
      .addCase(actions.hideSystemAlertAction, (state) => {
        state.system = alertsInitialState.system;
      }),
);
