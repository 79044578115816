import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { uploadFileV2Service } from 'api';
import { showSystemAlertAction } from 'components/Alerts/actions';
import router from 'next/router';

const prefix = 'upload/';

interface IUploadFileV2Returned {
  name: string;
}

export const uploadFileV2Thunk = createAsyncThunk<IUploadFileV2Returned, File>(
  `${prefix}uploadFileV2`,
  async (file: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadFileV2Service(file);
      const {
        body: { content_token, document_token },
      } = response;

      await router.push(
        `/editor?document=${document_token}&content=${content_token}`,
      );

      return {
        name: file.name,
      };
    } catch (err: any) {
      // wrong format
      if (err?.response?.status === 400) {
        dispatch(
          showSystemAlertAction({
            severity: 'error',
            text: `Sorry, the format of the file ${file.name} is not supported.`,
          }),
        );
      } else {
        dispatch(
          showSystemAlertAction({
            severity: 'error',
            text: 'Sorry, looks like something went wrong. Please try later.',
          }),
        );
      }

      captureException(err, {
        extra: {
          filename: file.name,
        },
      });
      return rejectWithValue(err.response.data);
    }
  },
);
