import { createReducer } from '@reduxjs/toolkit';
import { uploadFileV2Thunk } from 'components/Upload/actions';
import { take } from 'lodash';

import {
  getFileInfoThunk,
  requestFileJsonThunk,
  setActiveStructureItemAction,
  setChangedFieldAction,
  setChangedFieldThunk,
  setDemoAction,
  setEditorModeAction,
  setFileNameAction,
  setMatchWholeWordAction,
  setSearchFilterAction,
  setSearchQueryAction,
  setShowOnlyChangedAction,
} from './actions';
import { EDITOR_MODES, KEY_DIVIDER, SEARCH_FILTERS } from './constants';
import { generateId, updateChangedFieldsVisibility } from './helpers';
import type {
  EditorMode,
  Savefile,
  SavefileChangedFields,
  SavefileChangedFieldsVisibility,
  SavefileName,
  SavefileStructureState,
  SavefileToken,
  SearchFilters,
  ValueTypeFilter,
} from './types';

export interface EditorState {
  changedFields: SavefileChangedFields;
  changedFieldsVisibility: SavefileChangedFieldsVisibility;
  contentToken: string;
  documentToken: string;
  file: Savefile;
  mode: EditorMode;
  name: SavefileName;
  searchFilters: SearchFilters;
  searchMatchWholeWord: boolean;
  searchQuery: string;
  showOnlyChanged: boolean;
  structure: SavefileStructureState;
  valueTypeFilter: ValueTypeFilter;
  token: SavefileToken;
}

export const editorInitialState: EditorState = {
  changedFields: {},
  changedFieldsVisibility: {},
  contentToken: '',
  documentToken: '',
  file: '',
  mode: EDITOR_MODES.simple,
  name: '',
  searchFilters: SEARCH_FILTERS,
  searchMatchWholeWord: false,
  searchQuery: '',
  showOnlyChanged: false,
  structure: {
    active: '',
    openItems: {},
    path: '',
  },
  token: '',
  valueTypeFilter: 'all',
};

export const editorReducer = createReducer<EditorState>(
  editorInitialState,
  (builder) =>
    builder
      .addCase(setFileNameAction, (state, { payload: name }) => {
        if (name) {
          state.name = name;
        }
      })
      .addCase(uploadFileV2Thunk.fulfilled, (state, { payload }) => {
        const { name } = payload;
        const { searchQuery, structure, changedFieldsVisibility } =
          editorInitialState;

        state.name = name;
        state.changedFields = {};
        state.searchQuery = searchQuery;
        state.structure = structure;
        state.changedFieldsVisibility = changedFieldsVisibility;
      })
      .addCase(requestFileJsonThunk.fulfilled, (state, { payload }) => {
        const { contentToken, documentToken, fileContent } = payload;

        state.file = fileContent;
        state.contentToken = contentToken;
        state.documentToken = documentToken;
      })
      .addCase(getFileInfoThunk.fulfilled, (state, { payload }) => {
        const { title, extension } = payload;

        state.name = `${title}${extension}`;
      })
      .addCase(setDemoAction, (state, { payload }) => {
        const { searchQuery, structure, changedFieldsVisibility } =
          editorInitialState;

        state.file = payload;
        state.name = 'demo filename';
        state.token = '';

        state.searchQuery = searchQuery;
        state.structure = structure;
        state.changedFieldsVisibility = changedFieldsVisibility;
      })
      .addCase(setEditorModeAction, (state, { payload: mode }) => {
        state.mode = mode;
      })
      .addCase(setShowOnlyChangedAction, (state, { payload: filterFlag }) => {
        state.showOnlyChanged = filterFlag;
      })
      .addCase(setActiveStructureItemAction, (state, { payload }) => {
        const { id, path, open } = payload;

        state.searchQuery = '';
        state.structure.active = id;
        state.structure.path = path;

        if (open) {
          path.split(KEY_DIVIDER).forEach((itemKey, index) => {
            const itemPath = take(path.split(KEY_DIVIDER), index).join(
              KEY_DIVIDER,
            );
            const itemId = generateId(itemKey, itemPath);
            state.structure.openItems[itemId] = true;
          });
        } else {
          delete state.structure.openItems[id];
        }
      })
      .addCase(setChangedFieldAction, (state, { payload: field }) => {
        const { id, value, initialValue } = field;

        delete state.changedFields[id];

        if (initialValue !== value) {
          state.changedFields[id] = field;
        }
      })
      .addCase(setChangedFieldThunk.fulfilled, (state, { payload: field }) => {
        const { id, value, initialValue } = field;

        delete state.changedFields[id];

        if (initialValue !== value) {
          state.changedFields[id] = field;
        }
      })
      .addCase(setSearchFilterAction, (state, { payload: filter }) => {
        state.valueTypeFilter = filter;
      })
      .addCase(
        setMatchWholeWordAction,
        (state, { payload: shouldMatchWholeWord }) => {
          const { changedFields, searchQuery } = state;

          state.searchMatchWholeWord = shouldMatchWholeWord;
          state.changedFieldsVisibility = updateChangedFieldsVisibility(
            changedFields,
            searchQuery,
            shouldMatchWholeWord,
          );
        },
      )
      .addCase(setSearchQueryAction, (state, { payload: searchQuery }) => {
        const { changedFields, searchMatchWholeWord } = state;

        state.searchQuery = searchQuery;
        state.changedFieldsVisibility = updateChangedFieldsVisibility(
          changedFields,
          searchQuery,
          searchMatchWholeWord,
        );
      }),
);
