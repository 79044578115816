import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { downloadFileService, downloadFileV2Service } from 'api';
import { showSystemAlertAction } from 'components/Alerts/actions';
import type { SavefileName, SavefileToken } from 'components/Editor/types';
import fileDownload from 'js-file-download';

import { RootState } from '../../store/store';
import {
  contentTokenSelector,
  documentTokenSelector,
} from '../Editor/selectors';

const prefix = 'download/';

type DownloadFileData = {
  name: SavefileName;
  token: SavefileToken;
};

export const downloadFileThunk = createAsyncThunk(
  `${prefix}downloadFile`,
  async (data: DownloadFileData, { rejectWithValue, dispatch }) => {
    const { name, token } = data;

    try {
      const response = await downloadFileService(token);
      fileDownload(response.body, name);
    } catch (err: any) {
      captureException(err);

      dispatch(
        showSystemAlertAction({
          severity: 'error',
          text: 'Sorry, for some reason we could not start file downloading. Please try again.',
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

type DownloadFileV2Data = {
  name: SavefileName;
};

export const downloadFileV2Thunk = createAsyncThunk(
  `${prefix}downloadFileV2`,
  async (data: DownloadFileV2Data, { rejectWithValue, dispatch, getState }) => {
    const { name } = data;
    const state = getState() as RootState;
    const contentToken = contentTokenSelector(state);
    const documentToken = documentTokenSelector(state);

    try {
      const response = await downloadFileV2Service({
        contentToken,
        documentToken,
      });
      fileDownload(response.body, name);
    } catch (err: any) {
      captureException(err);

      dispatch(
        showSystemAlertAction({
          severity: 'error',
          text: 'Sorry, for some reason we could not start file downloading. Please try again.',
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);
