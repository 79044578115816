import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export type UploadState = {
  uploading: boolean;
};

export const uploadInitialState: UploadState = {
  uploading: false,
};

export const uploadReducer = createReducer<UploadState>(
  uploadInitialState,
  (builder) =>
    builder
      .addCase(actions.uploadFileV2Thunk.pending, (state) => {
        state.uploading = true;
      })
      .addCase(actions.uploadFileV2Thunk.fulfilled, (state) => {
        state.uploading = false;
      })
      .addCase(actions.uploadFileV2Thunk.rejected, (state) => {
        state.uploading = false;
      }),
);
