import {
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  map,
  mapValues,
  pickBy,
} from 'lodash';

export function removeEmptyObjects(obj: any): any {
  if (isArray(obj)) {
    const filtered = map(obj, (item) => removeEmptyObjects(item));
    return filtered.length > 0
      ? filtered.every((item) => isUndefined(item))
        ? undefined
        : filtered
      : undefined;
  } else if (isObject(obj)) {
    const filtered = pickBy(
      mapValues(obj, (value) => removeEmptyObjects(value)),
      (value) => {
        if (isArray(value) || isObject(value) || isUndefined(value)) {
          return !isEmpty(value);
        }
        return true;
      },
    );
    return !isEmpty(filtered) ? filtered : undefined;
  } else {
    return obj;
  }
}
