import { createAction } from '@reduxjs/toolkit';

import { AlertType } from './types';

const prefix = 'alerts/';

export const showSystemAlertAction = createAction<Omit<AlertType, 'id'>>(
  `${prefix}showSystemAlert`,
);
export const hideSystemAlertAction = createAction(`${prefix}hideSystemAlert`);
