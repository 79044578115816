import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { initialState, rootReducer } from './rootReducer';

const initializeStore = (preloadedState = initialState) => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['upload/start'],
        },
      }),
    preloadedState,
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;

export const store = initializeStore();

type TAppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<TAppDispatch>();
