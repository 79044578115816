import '../main.css';

import { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';

import { sendPageView } from '../lib/ga';

const { publicRuntimeConfig } = getConfig();

export const ThemeContext = React.createContext('dark');

export default function App(props: AppProps): React.ReactElement {
  const { Component, pageProps } = props;
  const router = useRouter();
  const [theme, setTheme] = React.useState('dark');

  React.useEffect(() => {
    setTheme(document.documentElement.getAttribute('data-theme') as string);

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'attributes') {
          setTheme(document.documentElement.getAttribute('data-theme') as string);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (url: URL) => {
      sendPageView(url);
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeContext.Provider value={theme}>
      <Provider store={store}>
        <Component {...pageProps} />
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        {publicRuntimeConfig.NODE_ENV === 'production' && (
          <>
            <script src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GOOGLE_ANALYTICS}`}/>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${publicRuntimeConfig.GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                  });
                `,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:3180672,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `,
              }}
            />
          </>
        )}
      </Provider>
    </ThemeContext.Provider>
  );
}
