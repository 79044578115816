import type { Patches, SavefileToken } from 'components/Editor/types';
import getConfig from 'next/config';
import request from 'superagent';

const { publicRuntimeConfig } = getConfig();

export async function uploadFileV2Service(file: any) {
  return request
    .post(getApiV2Url('file/upload'))
    .withCredentials()
    .attach('save_file', file);
}

export async function updateFileService(
  token: SavefileToken,
  patches: Patches,
) {
  return request
    .patch(getApiUrl('update'))
    .set('x-file-token', token)
    .send({ patches });
}

interface IUpdateFileV2ServiceArgs {
  contentToken: string;
  documentToken: string;
  patches: Patches;
}

export async function updateFileV2Service({
  contentToken,
  documentToken,
  patches,
}: IUpdateFileV2ServiceArgs) {
  return request
    .patch(getApiV2Url(`document/${documentToken}/content/${contentToken}`))
    .send({ patches });
}

export async function downloadFileAsJsonService({
  contentToken,
  documentToken,
}: {
  contentToken: string;
  documentToken: string;
}) {
  return request
    .get(getApiV2Url(`document/${documentToken}/content/${contentToken}`))
    .set('accept', 'application/json');
}

export async function downloadFileService(token: SavefileToken) {
  return request
    .get(getApiUrl('download'))
    .responseType('blob')
    .set('accept', 'application/octet-stream')
    .set('x-file-token', token);
}

interface IDownloadFileV2ServiceArgs {
  contentToken: string;
  documentToken: string;
}

export async function downloadFileV2Service({
  contentToken,
  documentToken,
}: IDownloadFileV2ServiceArgs) {
  return request
    .get(
      getApiV2Url(`document/${documentToken}/content/${contentToken}/download`),
    )
    .responseType('blob')
    .set('accept', 'application/octet-stream');
}

export function getFileInfoService(documentToken: string) {
  return request
    .get(getApiV2Url(`document/${documentToken}/info`))
    .set('accept', 'application/json');
}

export function uploadRenpyFilesToRepackService(
  saveFile: any,
  securityKeysFile: any,
) {
  return request
    .post(getApiUrl('file/repack/renpy'))
    .withCredentials()
    .attach('save_file', saveFile)
    .attach('security_keys', securityKeysFile)
    .responseType('blob')
    .set('accept', 'application/octet-stream');
}

function getApiUrl(path: string) {
  return `${publicRuntimeConfig.API_URL}/${path}`;
}

function getApiV2Url(path: string) {
  return `${publicRuntimeConfig.API_V2_URL}/${path}`;
}
