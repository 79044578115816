import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import {
  downloadFileAsJsonService,
  getFileInfoService,
  updateFileService,
  updateFileV2Service,
} from 'api';
import { showSystemAlertAction } from 'components/Alerts/actions';
import { downloadFileThunk } from 'components/Download/actions';
import {
  contentTokenSelector,
  documentTokenSelector,
} from 'components/Editor/selectors';
import { isEmpty } from 'lodash';
import { RootState } from 'store/store';

import { prepareReplacePatch, prepareReplacePatches } from './helpers';
import type {
  EditorMode,
  SavefileChangedField,
  SavefileChangedFields,
  SavefileInfo,
  SavefileName,
  SavefileToken,
  ValueTypeFilter,
} from './types';

const prefix = 'editor/';

// ACTIONS
export const setEditorModeAction = createAction<EditorMode>(`${prefix}setMode`);
export const setSearchQueryAction = createAction<string>(
  `${prefix}setSearchQuery`,
);
export const setActiveStructureItemAction = createAction<{
  id: string;
  path: string;
  open?: boolean;
}>(`${prefix}setActiveStructureItem`);
export const setShowOnlyChangedAction = createAction<boolean>(
  `${prefix}setShowOnlyChanged`,
);
export const setChangedFieldAction = createAction<SavefileChangedField>(
  `${prefix}setChangedField`,
);
export const setDemoAction = createAction<string>(`${prefix}setDemo`);
export const setSearchFilterAction = createAction<ValueTypeFilter>(
  `${prefix}setSearchFilter`,
);
export const setMatchWholeWordAction = createAction<boolean>(
  `${prefix}setMatchWholeWord`,
);
export const setFileNameAction = createAction<string | null>(
  `${prefix}setFileName`,
);

export const setChangedFieldThunk = createAsyncThunk(
  `${prefix}setChangedField`,
  async (data: SavefileChangedField) => {
    return data;
  },
);

// ASYNC THUNKS
interface IUpdateFileData {
  field: SavefileChangedField;
}

export const updateFileV2Thunk = createAsyncThunk(
  `${prefix}updateFileV2`,
  async (data: IUpdateFileData, { rejectWithValue, dispatch, getState }) => {
    const { field } = data;
    const patches = prepareReplacePatch(field);
    const state = getState() as RootState;
    const contentToken = contentTokenSelector(state);
    const documentToken = documentTokenSelector(state);

    try {
      const response = await updateFileV2Service({
        contentToken,
        documentToken,
        patches,
      });

      return response.body;
    } catch (err: any) {
      captureException(err);

      dispatch(
        showSystemAlertAction({
          severity: 'error',
          text: 'Sorry, for some reason we could not update the file. Please try again.',
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

interface IDownloadFileAsJsonData {
  contentToken: string;
  documentToken: string;
}

interface IDownloadFileAsJsonReturned extends IDownloadFileAsJsonData {
  fileContent: string;
}

export const requestFileJsonThunk = createAsyncThunk<
  IDownloadFileAsJsonReturned,
  IDownloadFileAsJsonData
>(`${prefix}downloadFileAsJson`, async (data, { dispatch }) => {
  const { contentToken, documentToken } = data;

  try {
    const response = await downloadFileAsJsonService({
      contentToken,
      documentToken,
    });

    return {
      contentToken,
      documentToken,
      fileContent: response.text,
    };
  } catch (error: any) {
    captureException(error);

    dispatch(
      showSystemAlertAction({
        severity: 'error',
        text: 'Sorry, for some reason we could not download the file. Please try again.',
      }),
    );

    return error.response.data;
  }
});

type UpdateFileData = {
  token: SavefileToken;
  fields: SavefileChangedFields;
};

/**
 * @deprecated
 */
export const updateFileThunk = createAsyncThunk(
  `${prefix}updateFile`,
  async (data: UpdateFileData, { rejectWithValue, dispatch }) => {
    const { token, fields } = data;
    const patches = prepareReplacePatches(fields);

    try {
      const response = await updateFileService(token, patches);
      return response.body;
    } catch (err: any) {
      captureException(err);

      dispatch(
        showSystemAlertAction({
          severity: 'error',
          text: 'Sorry, for some reason we could not update the file. Please try again.',
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);

export type UpdateAndDownloadFileData = {
  name: SavefileName;
  token: SavefileToken;
  fields: SavefileChangedFields;
};

/**
 * @deprecated
 */
export const updateAndDownloadFileThunk = createAsyncThunk<
  any,
  UpdateAndDownloadFileData
>(`${prefix}updateAndDownloadFile`, async (data, { dispatch }) => {
  const { token, fields, name } = data;

  if (!isEmpty(fields)) {
    await dispatch(updateFileThunk({ fields, token }));
  }

  return dispatch(downloadFileThunk({ name, token }));
});

interface IGetFileInfoData {
  documentToken: string;
}

export const getFileInfoThunk = createAsyncThunk(
  `${prefix}getFileInfo`,
  async (data: IGetFileInfoData, { dispatch, rejectWithValue }) => {
    const { documentToken } = data;

    try {
      const response = await getFileInfoService(documentToken);
      return response.body as SavefileInfo;
    } catch (err: any) {
      captureException(err);

      dispatch(
        showSystemAlertAction({
          severity: 'error',
          text: 'Sorry, for some reason we could not retrieve a file info. Please try to reload the page.',
        }),
      );

      return rejectWithValue(err.response.data);
    }
  },
);
