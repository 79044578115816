import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export type DownloadState = {
  downloading: boolean;
};

export const downloadInitialState: DownloadState = {
  downloading: false,
};

export const downloadReducer = createReducer<DownloadState>(
  downloadInitialState,
  (builder) =>
    builder
      .addCase(actions.downloadFileThunk.pending, (state) => {
        state.downloading = true;
      })
      .addCase(actions.downloadFileThunk.fulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(actions.downloadFileThunk.rejected, (state) => {
        state.downloading = false;
      })
      .addCase(actions.downloadFileV2Thunk.pending, (state) => {
        state.downloading = true;
      })
      .addCase(actions.downloadFileV2Thunk.fulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(actions.downloadFileV2Thunk.rejected, (state) => {
        state.downloading = false;
      }),
);
