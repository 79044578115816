export const KEY_DIVIDER = '{divider}';

export const EDITOR_MODES = {
  full: 'full',
  simple: 'simple',
} as const;

export const MARK_TAG = {
  end: '</mark>',
  start: '<mark>',
} as const;

export const SEARCH_FILTERS = {
  key: true,
  path: true,
  value: true,
};

export const VALUE_TYPE_FILTERS = {
  all: 'All',
  boolean: 'Boolean',
  number: 'Number',
  string: 'String',
} as const;
