import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const isGtagAvailable = publicRuntimeConfig.NODE_ENV === 'production';

// log the pageview with their URL
export const sendPageView = (url: URL) => {
  if (isGtagAvailable && typeof window.gtag === 'function') {
    window.gtag('config', publicRuntimeConfig.GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
  params?: Record<string, any>;
};

// log specific events happening
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEvent = ({ action, category = 'engagement', label, value, params = {} }: GTagEvent) => {
  if (isGtagAvailable && typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      ...params,
    });
  }
};
