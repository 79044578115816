import {
  filter,
  forOwn,
  includes,
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  reduce,
  toLower,
  toString,
} from 'lodash';

import { KEY_DIVIDER, MARK_TAG } from '../constants';
import {
  Patches,
  SavefileArray,
  SavefileChangedField,
  SavefileChangedFields,
  SavefileChangedFieldsVisibility,
  SavefileField,
  SavefileItem,
  SavefileObject,
  SavefileStructure,
  SearchFilters,
  ValueTypeFilter,
} from '../types';

const compareNaturally = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
}).compare;

export function removeAllSpaces(text: string): string {
  return text.replace(/ /g, '');
}

export function escapeRegex(text: string): string {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function highlightText(
  term: string,
  text: string,
  searchMatchWholeWord: boolean,
): string {
  if (searchMatchWholeWord) {
    return toLower(term) === toLower(text)
      ? `${MARK_TAG.start}${text}${MARK_TAG.end}`
      : text;
  } else {
    return text.replace(
      new RegExp(escapeRegex(term), 'gi'),
      (match) => `${MARK_TAG.start}${match}${MARK_TAG.end}`,
    );
  }
}

export function removeHighlighting(text: string): string {
  return text
    .replace(new RegExp(MARK_TAG.start, 'g'), '')
    .replace(new RegExp(MARK_TAG.end, 'g'), '');
}

export function prepareReplacePatch(field: SavefileChangedField): Patches {
  const { path, value, oldValue } = field;
  const patchedPath = preparePathForPatch(path);

  return [
    {
      old_value: oldValue,
      op: 'replace',
      path: `/${patchedPath}`,
      value,
    },
  ];
}

export function prepareReplacePatches(fields: SavefileChangedFields) {
  const patches: Patches = [];

  if (!isEmpty(fields)) {
    forOwn(fields, (field) => {
      const { path, value } = field;
      const patchedPath = preparePathForPatch(path);

      patches.push({
        op: 'replace',
        path: `/${patchedPath}`,
        value,
      });
    });
  }

  return patches;
}

function preparePathForPatch(path: string) {
  // changing all dots KEY_DIVIDER(s) to slash (/)
  return path
    .replace(new RegExp('~', 'g'), '~0')
    .replace(new RegExp('/', 'g'), '~1')
    .replace(new RegExp(KEY_DIVIDER, 'g'), '/');
}

export function generateFileStructure(
  savefileObject: SavefileObject | SavefileArray,
) {
  function generateStructure(
    fileObject: SavefileObject | SavefileArray,
    path = '',
  ) {
    return reduce(
      fileObject,
      (acc: SavefileStructure, item: SavefileItem, keyValue: string) => {
        const key = toString(keyValue);
        const id = generateId(key, path);
        const fullPath = path ? `${path}${KEY_DIVIDER}${key}` : key;

        if ((isArray(item) || isObject(item)) && !isEmpty(item)) {
          acc.push({
            childs: generateStructure(item, fullPath),
            id,
            keyValue: key,
            path: fullPath,
          });
        }

        return acc;
      },
      [],
    );
  }

  return generateStructure(savefileObject);
}

export function sortStructure(structure: SavefileStructure) {
  const sorted = sortFieldsByKey(structure);

  sorted.forEach((item) => {
    sortStructure(item.childs);
  });

  return sorted;
}

export function generateFileFields(savefileObject: SavefileObject) {
  const fields: SavefileField[] = [];

  function generateFields(fileObject: SavefileItem, path = '') {
    forOwn(fileObject, (item: SavefileItem, keyValue: string) => {
      const key = toString(keyValue);
      const id = generateId(key, path);
      const pathWithKey = path ? `${path}${KEY_DIVIDER}${key}` : key;

      if (isArray(item) || isObject(item)) {
        generateFields(item, pathWithKey);
      } else {
        fields.push({ id, initialValue: item, keyValue: key, path });
      }
    });
  }

  generateFields(savefileObject);

  return fields;
}

export function sortFieldsByKey(structure: SavefileStructure) {
  return structure.sort((firstItem, secondItem) => {
    return compareNaturally(firstItem.keyValue, secondItem.keyValue);
  });
}

export function sortFieldsByKeyAndPath(
  structurePath: string,
  searchQuery: string,
  savefileFields: SavefileField[],
) {
  return savefileFields.sort((firstItem, secondItem) => {
    const rootPath = searchQuery ? '' : structurePath;
    const isFirstItemRoot = firstItem.path === rootPath;
    const isSecondItemRoot = secondItem.path === rootPath;
    const isPathsIdentical = firstItem.path === secondItem.path;

    if ((isFirstItemRoot && isSecondItemRoot) || isPathsIdentical) {
      return compareNaturally(firstItem.keyValue, secondItem.keyValue);
    }

    if (!isFirstItemRoot && !isSecondItemRoot) {
      return compareNaturally(firstItem.path, secondItem.path);
    }

    if (isFirstItemRoot && !isSecondItemRoot) {
      return -1;
    }

    if (!isFirstItemRoot && isSecondItemRoot) {
      return 1;
    }

    return 0;
  });
}

export function filterByValueType(
  savefileFields: SavefileField[],
  valueTypeFilter: ValueTypeFilter,
) {
  return filter(savefileFields, (field) => {
    if (valueTypeFilter === 'all') {
      return true;
    }

    return typeof field.initialValue === valueTypeFilter;
  });
}

export function filterFieldsByOnlyChanged(
  changedFields: SavefileChangedFields,
  savefileFields: SavefileField[],
) {
  return filter(savefileFields, (field) => {
    return changedFields[field.id] !== undefined;
  });
}

export function filterFieldsByStructurePath(
  structurePath: string,
  savefileFields: SavefileField[],
) {
  return filter(savefileFields, (field) => {
    if (structurePath.indexOf(KEY_DIVIDER) === -1) {
      return field.path.split(KEY_DIVIDER)[0] === structurePath;
    }

    return field.path.indexOf(structurePath) === 0;
  });
}

export function matchSearchResult(
  text: string,
  searchQuery: string,
  searchMatchWholeWord: boolean,
) {
  return searchMatchWholeWord
    ? text === searchQuery
    : includes(text, searchQuery);
}

export function shouldBeShownByValue(
  field: SavefileField,
  searchQuery: string,
  changedFieldsVisibility: SavefileChangedFieldsVisibility,
  searchMatchWholeWord: boolean,
) {
  const { id, initialValue } = field;
  const searchQueryLower = toLower(searchQuery);
  const initialValueLower = toLower(toString(initialValue));
  const isChangedFieldVisible = changedFieldsVisibility[id];

  return isUndefined(isChangedFieldVisible)
    ? matchSearchResult(
      initialValueLower,
      searchQueryLower,
      searchMatchWholeWord,
    )
    : isChangedFieldVisible;
}

export function sortSearchResult(
  first: SavefileField,
  second: SavefileField,
  searchQuery: string,
  searchQueryLowerNoSpaces: string,
  keyFilter: boolean,
  valueFilter: boolean,
  changedFieldsVisibility: SavefileChangedFieldsVisibility,
  searchMatchWholeWord: boolean,
): number {
  // Sorting by value
  if (valueFilter) {
    if (
      shouldBeShownByValue(
        first,
        searchQuery,
        changedFieldsVisibility,
        searchMatchWholeWord,
      ) &&
      !shouldBeShownByValue(
        second,
        searchQuery,
        changedFieldsVisibility,
        searchMatchWholeWord,
      )
    ) {
      return -1;
    } else if (
      !shouldBeShownByValue(
        first,
        searchQuery,
        changedFieldsVisibility,
        searchMatchWholeWord,
      ) &&
      shouldBeShownByValue(
        second,
        searchQuery,
        changedFieldsVisibility,
        searchMatchWholeWord,
      )
    ) {
      return 1;
    }
  }

  // Sorting by key
  if (keyFilter) {
    if (
      includes(first.keyValue, searchQueryLowerNoSpaces) &&
      !includes(second.keyValue, searchQueryLowerNoSpaces)
    ) {
      return -1;
    } else if (
      !includes(first.keyValue, searchQueryLowerNoSpaces) &&
      includes(second.keyValue, searchQueryLowerNoSpaces)
    ) {
      return 1;
    }
  }

  return 0;
}

export function filterFieldsBySearchQuery(
  searchQuery: string,
  savefileFields: SavefileField[],
  changedFieldsVisibility: SavefileChangedFieldsVisibility,
  searchFilters: SearchFilters,
  searchMatchWholeWord: boolean,
) {
  const searchQueryLower = toLower(searchQuery);
  const searchQueryLowerNoSpaces = removeAllSpaces(searchQueryLower);
  const {
    key: keyFilter,
    value: valueFilter,
    path: pathFilter,
  } = searchFilters;

  return filter(savefileFields, (field) => {
    const { keyValue, path } = field;
    const keyLower = toLower(keyValue);
    const pathLower = toLower(path);

    return (
      (keyFilter &&
        matchSearchResult(
          keyLower,
          searchQueryLowerNoSpaces,
          searchMatchWholeWord,
        )) ||
      (pathFilter &&
        matchSearchResult(
          pathLower,
          searchQueryLowerNoSpaces,
          searchMatchWholeWord,
        )) ||
      (valueFilter &&
        shouldBeShownByValue(
          field,
          searchQuery,
          changedFieldsVisibility,
          searchMatchWholeWord,
        ))
    );
  }).sort((first, second) => {
    return sortSearchResult(
      first,
      second,
      searchQuery,
      searchQueryLowerNoSpaces,
      keyFilter,
      valueFilter,
      changedFieldsVisibility,
      searchMatchWholeWord,
    );
  });
}

export function generateId(key: string, path: string) {
  return `_${key}__${path}_`;
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function updateChangedFieldsVisibility(
  changedFields: SavefileChangedFields,
  searchQuery: string,
  searchMatchWholeWord: boolean,
): SavefileChangedFieldsVisibility {
  const visibility: SavefileChangedFieldsVisibility = {};
  const searchQueryLower = toLower(searchQuery);

  forOwn(changedFields, (changedField) => {
    if (searchQueryLower === '') {
      visibility[changedField.id] = true;
    } else {
      const valueLower = toLower(toString(changedField.value));

      visibility[changedField.id] = matchSearchResult(
        valueLower,
        searchQueryLower,
        searchMatchWholeWord,
      );
    }
  });

  return visibility;
}
