import { alertsInitialState, alertsReducer } from 'components/Alerts/reducer';
import {
  downloadInitialState,
  downloadReducer,
} from 'components/Download/reducer';
import { editorInitialState, editorReducer } from 'components/Editor/reducer';
import { uploadInitialState, uploadReducer } from 'components/Upload/reducer';
import { combineReducers } from 'redux';

export const initialState = {
  alerts: alertsInitialState,
  download: downloadInitialState,
  editor: editorInitialState,
  upload: uploadInitialState,
};

export const rootReducer = combineReducers<typeof initialState>({
  alerts: alertsReducer,
  download: downloadReducer,
  editor: editorReducer,
  upload: uploadReducer,
});
